import React from "react"

// There is no default home page for the app site, so just navigate to the new quote page
const Test = () => {
  return (
    <div>
      <div>Testing testing 123</div>
      <iframe src={"http://localhost:8000/tenants/app/quote-start"} />
    </div>
  )
}

export default Test
